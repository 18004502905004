<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-21 14:22:21
 * @LastEditTime: 2021-03-23 11:48:46
 -->
<template>
  <div
    class="downloadAppDiv"
    style="padding: 40px 0 0 0"
  >
    <div class="text-status-center">
      <img
        style="width: 100px; height: auto"
        :src="downIcon"
        alt
      >
    </div>
    <div
      style="font-size: 20px; margin-top: 10px"
      class="text-status-center font-weight-600"
    >
      JE大脑
    </div>
    <div
      style="font-size: 18px; margin-top: 40px"
      class="text-status-center"
    >
      请选择您的下载方式
    </div>
    <div class="downloadTabs">
      <div
        @click="downloadStatus = 'Android'"
        :style="downloadStatus == 'Android' && 'color: #409EFF; border-color: #409EFF'"
        class="downloadT"
      >
        Android
      </div>
      <div
        @click="downloadStatus = 'IOS'"
        :style="downloadStatus == 'IOS' && 'color: #409EFF; border-color: #409EFF'"
        class="downloadT"
      >
        IOS
      </div>
    </div>
    <div
      class="text-status-center"
      style="font-size: 18px; margin-top: 40px;"
    >
      <div
        class="wh300"
        style="margin: 0 auto;"
      >
        <div
          v-show="downloadStatus == 'Android'"
          class="qrCode"
          id="qrCode"
          ref="qrCodeDiv"
        />
        <div
          v-show="downloadStatus == 'IOS'"
          class="qrCode"
          id="qrCodeIos"
          ref="qrCodeDivIos"
        />
      </div>
      <div style="margin-top: 60px">
        <div
          @click="downloadClick(downloadStatus)"
          class="downloadButton"
        >
          下载安装
        </div>
      </div>
    </div>
    <!-- 下载右上角提示下载地址 -->
    <div
      v-if="downloadShow"
      @click="downloadClose"
      class="downloadTS"
    >
      <img
        style="width: 80%;height: auto"
        :src="download"
        alt
      >
    </div>
  </div>
</template>

<script>
import android from '@/assets/downImg/android.png';
import ios from '@/assets/downImg/ios.png';
import download from '@/assets/downImg/weixinDown.png';
import downIcon from '@/assets/downImg/downIcon.png';
import borderWX from '@/assets/downImg/borderWX.png';
import QRCode from 'qrcodejs2';

const iosUrl = 'https://apps.apple.com/cn/app/je%E5%A4%A7%E8%84%91/id1557393080'; // ios下载地址
const androidUrl = 'http://suanbanyun-app.oss-cn-beijing.aliyuncs.com/Jedanao/Android/2021030909160222_0317150838.apk'; // ios下载地址

export default {
  data() {
    return {
      android,
      ios,
      borderWX,
      downIcon,
      download,
      activeName: 'Android',
      downloadShow: false, // 微信下载APP提示
      downloadStatus: 'Android',
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.bindQRCode();
    });
  },
  methods: {
    downloadClick(key) {
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        key == 'Android' && (this.downloadShow = true);
        key == 'IOS' && window.open(iosUrl);
      } else {
        key == 'Android' && window.open(androidUrl);
        key == 'IOS' && window.open(iosUrl);
      }
    },
    downloadClose() {
      this.downloadShow = false;
    },
    bindQRCode() {
      // eslint-disable-next-line no-new
      new QRCode(this.$refs.qrCodeDiv, {
        text: androidUrl,
        width: 115,
        height: 115,
        colorDark: '#333333', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
      });
      // eslint-disable-next-line no-new
      new QRCode(this.$refs.qrCodeDivIos, {
        text: iosUrl,
        width: 115,
        height: 115,
        colorDark: '#333333', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.downloadAppDiv {
  position: relative;
  .text-status-center {
    text-align: center;
  }
  .font-weight-600 {
    font-weight: 600;
  }
  .downloadTS {
    text-align: center;
    padding-top: 100px;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
  }
  .wh300 {
    width: 800px;
    height: 800px;
  }
  .downloadTabs {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .downloadT {
      width: 50%;
      height:  150px;
      line-height: 150px;
      text-align: center;
      border-bottom: 2px solid #ccc;
    }
  }
  .downloadButton {
    border-radius: 5px;
    padding: 40px 0;
    width: 50%;
    font-size: 20px;
    color: #FFF;
    background-color: #409EFF;
    border-color: #409EFF;
    margin: 0 auto;
  }
}
</style>
<style lang="scss">
.qrCode {
  height: 100%;
  background-image: url('../../assets/downImg/borderWX.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  // img {

  // }
}
.downloadAppDiv {
  .el-tabs__item {
    font-size: 20px;
    height: 60px;
    line-height: 60px;
  }
  .el-button {
    padding: 20px 0;
    width: 50%;
    font-size: 20px;
  }
}
</style>
